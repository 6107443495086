import type { ConnectionState } from "livekit-client-legacy";
import type { DeviceNodeHealth } from "@skydio/channels/src/device_node_health_summary_pb";
import type { DeviceNodePeerStats } from "@skydio/channels/src/device_node_peer_stats_pb";
import type { GimbalFlashlightStatus } from "@skydio/channels/src/gimbal_flashlight_status_pb";
import type { LandingPadTrackerSimplifiedState } from "@skydio/channels/src/landing_pad_tracker_simplified_state_pb";
import type { ReturnToDockInfo } from "@skydio/channels/src/return_to_dock_info_pb";
import type { SafeLandingInfo } from "@skydio/channels/src/safe_landing_info_pb";
import type { SpeakerMicAudioFiles } from "@skydio/channels/src/speaker_mic_list_audio_files_pb";
import type { SpeakerMicStatus } from "@skydio/channels/src/speaker_mic_status_pb";
import type { SpotlightSystemStatus } from "@skydio/channels/src/spotlight_system_status_pb";
import type { StreamCameraCalibration } from "@skydio/channels/src/stream_camera_calibration_pb";
import type { VehicleSettings } from "@skydio/channels/src/update_vehicle_settings_pb";
import type { MinimalAutoRegionState } from "@skydio/channels/src/user_camera_autoregion_state_pb";
import type { Meters } from "@skydio/math";
import type { FlightState } from "@skydio/pbtypes/pbtypes/gen/ambassador/flight_state_pb";
import type { TransPb } from "@skydio/pbtypes/pbtypes/gen/body/trans_pb";
import type { CameraIntrinsics } from "@skydio/pbtypes/pbtypes/gen/calibration/camera_intrinsics_pb";
import type { FlightPhaseEnum } from "@skydio/pbtypes/pbtypes/gen/common/flight_phase_enum_pb";
import type { GoToWaypointSource } from "@skydio/pbtypes/pbtypes/gen/skills/go_to_waypoint_source_pb";
import type { BlockingPrompt } from "@skydio/pbtypes/pbtypes/gen/user_prompts/blocking_prompt_pb";
import type { PortStatusToConsumers } from "@skydio/pbtypes/pbtypes/infrastructure/drivers/attachment_manager/attachment_manager_pb";
import type { InterceptOrbitPoiMode_Enum } from "@skydio/pbtypes/pbtypes/vehicle/skills/actions/orbit_poi/orbit_poi";
import type {
  GoToWaypointState,
  GraphMotionArgs,
  LookAtMotionArgs,
  TraversalMotionArgs,
  Waypoint,
} from "@skydio/pbtypes/pbtypes/vehicle/skills/waypoints_pb";
import type { APIRequestState } from "@skydio/redux_util/src";
import type { WebRTCConnectionState } from "@skydio/skybus/src/transport/types";

export type MovementDirection =
  | "forwards"
  | "backwards"
  | "left"
  | "right"
  | "up"
  | "down"
  | "gimbalUp"
  | "gimbalDown"
  | "yawLeft"
  | "yawRight";

export type TeleopAction =
  | "toggleObstacleAvoidance"
  | "togglePointerLock"
  | "increaseSpeed"
  | "decreaseSpeed"
  | "boost"
  | "crawl"
  | "zoomIn"
  | "zoomOut"
  | "increaseExposure"
  | "decreaseExposure"
  | "takePhoto"
  | "workflowNext"
  | "workflowPrevious"
  | "returnToDock"
  | "cancel"
  | "toggleControlsMenu"
  | "autoFocusCenter"
  | "clearFocusCenter"
  | "zoomOneShortcut"
  | "zoomTwoShortcut"
  | "zoomThreeShortcut"
  | "zoomFourShortcut"
  | "zoomFiveShortcut"
  | "zoomSixShortcut"
  | "toggleStrobeLights"
  | "thermalToggle"
  | "resetGimbalPitch"
  | "toggleRgbLights"
  | "toggleARMarkers"
  | "toggleARStreetOverlays";

export interface MouseOrientation {
  pitch: number;
  heading: number;
}

export enum GamepadInputType {
  AXIS = "AXIS",
  BUTTON = "BUTTON",
}
// map common name to axis/button we read in value from
export enum GamepadMovementInputs {
  GAMEPAD_AXES_LEFT_STICK_X = 0,
  GAMEPAD_AXES_LEFT_STICK_Y = 1,
  GAMEPAD_AXES_RIGHT_STICK_X = 2,
  GAMEPAD_AXES_RIGHT_STICK_Y = 3,
  GAMEPAD_BUTTON_LT = 6,
  GAMEPAD_BUTTON_RT = 7,
}
export type GamepadMovementInputInfo = { input: GamepadMovementInputs; type: GamepadInputType };

export enum GamepadMode {
  MODE_1 = "MODE 1",
  MODE_2 = "MODE 2",
  MODE_3 = "MODE 3",
  VIDEO_GAME_MODE = "VIDEO GAME MODE",
}

// gamepad support for teleop actions
export enum GamepadActionInputs {
  GAMEPAD_BUTTON_DPAD_UP = 12,
  GAMEPAD_BUTTON_DPAD_DOWN = 13,
  GAMEPAD_BUTTON_DPAD_LEFT = 14,
  GAMEPAD_BUTTON_DPAD_RIGHT = 15,
  GAMEPAD_BUTTON_Y = 3,
  GAMEPAD_BUTTON_X = 2,
  GAMEPAD_BUTTON_RB = 5,
  GAMEPAD_BUTTON_LB = 4,
  GAMEPAD_BUTTON_A = 0,
  GAMEPAD_BUTTON_B = 1,
}

// NOTE(sam): Eventually we should implement some more sophisticated logic to determine what skills
// we show, but for now we just want to show these to all users
// See SkillSelector.tsx for some logic on which to show. These values should match mobile params.
export enum CLOUD_SKILLS {
  WAYPOINTS = "MissionRunner",
  LEGACY_MISSION_PLANNER = "PatrolPlanner",
  MISSION_PLANNER = "MissionPlanner",
  MANUAL = "FreeFly",
  SITE_MAPPER = "SiteMapper",
  // special case, this is not a vehicle skill
  MISSION_PLANNER_V2 = "MissionPlannerV2",
}

export type speedSetting = {
  min: number;
  max: number;
  default: number;
  step: number;
  translationToYaw: number;
  translationToPitch: number;
};

export enum MANUAL_FLIGHT_BLOCKERS {
  SETTINGS_MENU_OPEN = "SETTINGS_MENU_OPEN",
  FEEDBACK_MENU_OPEN = "FEEDBACK_MENU_OPEN",
  DEBUG_MENU_OPEN = "DEBUG_MENU_OPEN",
  CLICK_TO_FLY = "CLICK_TO_FLY",
  GO_TO_WAYPOINT = "GO_TO_WAYPOINT",
  INPUT_FOCUSED = "INPUT_FOCUSED",
}

export interface PilotState {
  vehicleConnection: APIRequestState & {
    clientId: string;
    downloadBitrate: number;
    uploadBitrate: number;
    status: WebRTCConnectionState | ConnectionState;
  };
  sessionId: string;
  runmode: string;
  activeFaults: number[];
  takeoffRequested: boolean;
  commandeerRequested: boolean;
  flightState?: FlightState.Enum;
  obstacleSetting: { safety?: number; mode?: VehicleSettings.ObstacleMarginMode };
  movementKeybindings: Record<MovementDirection, string>;
  teleopActionKeybindings: Record<TeleopAction, string>;
  keyboardMovementInputs: Record<MovementDirection, number>; // number corresponds to first key press time
  teleopActionInputs: Record<TeleopAction, number>; // number corresponds to first key press time
  teleopActionSources: Record<TeleopAction, string>; // source of teleop action: gamepad or keyboard
  mouseInput: {
    orientation: Partial<MouseOrientation>; // allow nullable fields
    locked: boolean;
    lastMouseMove: number; // timestamp of last mouse move event
  };
  gamepadConnected: boolean;
  gamepadMapping: GamepadMappingType;
  gamepadSettings: {
    mode: GamepadMode;
    expoLevel: { default: number; value: number };
    throttleSensitivity: { default: number; value: number };
    yawSensitivity: { default: number; value: number };
    pitchSensitivity: { default: number; value: number };
  };
  desiredGamepadSettings: {
    mode: GamepadMode;
    expoLevel: { default: number; value: number };
    throttleSensitivity: { default: number; value: number };
    yawSensitivity: { default: number; value: number };
    pitchSensitivity: { default: number; value: number };
  };
  gamepadMovementInputs: Record<MovementDirection, number>; // number corresponds to analog value
  clickToFly: {
    enabled: boolean;
    clickStart: THREE.Vector3;
    clickEnd: THREE.Vector3;
    scrollEnd: THREE.Vector3;
    active: number;
    displayTrail: boolean;
    desired: boolean;
  };
  skillState?: string; // TODO - get useful information from skill
  blockingPrompt?: BlockingPrompt.AsObject;
  buttonInputs: {
    elevation?: number;
    range?: number;
  };
  flightId: string;
  flightPhaseId: FlightPhaseEnum.Enum;
  movingAutonomously?: boolean;
  mostRecentPilot: {
    clientId: string;
    updatedAt: number;
  };
  positionCommand: boolean;
  velocityCommand: boolean;
  desiredPose: { position?: THREE.Vector3; heading?: number; pitch?: number; timestamp?: number };
  imu_T_subject_cam?: TransPb.AsObject;
  eo_imu_T_cam?: TransPb.AsObject;
  ir_imu_T_cam?: TransPb.AsObject;
  gimbal_cam_R_imu?: THREE.Quaternion;
  nav_T_gimbal_camera_imu?: { transform: TransPb.AsObject; timestamp: number };
  cameraIntrinsics?: CameraIntrinsics.AsObject;
  vehiclePosition?: THREE.Vector3;
  vehicleVelocity?: THREE.Vector3;
  vehicleNominalTranslationSpeed: number;
  desiredVehicleNominalTranslationSpeed: number;
  desiredZoomLevel: number;
  zoomCorrectionFactor: number;
  vehicleSpeedChangeTime: number;
  vehicleSpeedSettings: speedSetting;
  crawlModeActive: boolean;
  boostModeActive: boolean;
  ignoreObstacleBasedSpeedSetting: boolean;
  displayPreciseTeleopAr: boolean;
  desiredDisplayPreciseTeleopAr: boolean;
  displayPointerLockInnerRing: boolean;
  goToWaypointRequest: {
    target?: Waypoint.AsObject;
    lookAtArgs?: LookAtMotionArgs.AsObject;
    traversalArgs?: TraversalMotionArgs.AsObject;
    graphArgs?: GraphMotionArgs.AsObject;
    useGlobalGraph?: boolean;
    timestamp: number;
  };
  goToWaypointStatus: {
    state?: GoToWaypointState.Enum;
    source?: GoToWaypointSource.Enum;
  };
  returnToDockInfo?: ReturnToDockInfo.AsObject;
  safeLandingInfo?: SafeLandingInfo.AsObject;
  latestValidTargetLandingPointId?: SafeLandingInfo.AsObject["targetId"];
  gimbalFlashlightStatus?: GimbalFlashlightStatus.AsObject;
  thirdPersonViewActive: boolean;
  manualFlightBlockers: {
    blocked: boolean;
    blockers: MANUAL_FLIGHT_BLOCKERS[];
    blockerRemovalTimestamp: number;
  };
  streamCameraCalibration?: StreamCameraCalibration.AsObject;
  configReceived: boolean;
  landingPadTracker?: LandingPadTrackerSimplifiedState.AsObject;
  rssi?: number;
  vehicleAmbassadorStatusLastUpdated?: Date;
  /**
   * The default height to fly to when using the "Fly Here Now" feature.
   * Used for both POIs and fly-by waypoints.
   */
  defaultPoiHeight: Meters;
  userCameraAutoRegionState?: MinimalAutoRegionState.AsObject;
  /**
   * The default orbit intercept mode to use when using the "Orbit Point" feature.
   */
  defaultOrbitInterceptMode: InterceptOrbitPoiMode_Enum;
  /**
   * The default orbit direction to use when using the "Orbit Point" feature.
   */
  defaultOrbitDirection: "clockwise" | "counterclockwise";
  /**
   * Control whether the look_at_point option is enabled for the "Orbit Point" feature.
   */
  defaultLookAtPointActive: boolean;
  /**
   * If active, the vehicle will look at the final point of the mission in a "Fly Here" mission
   */
  lookAtFinalPointActive: boolean;
  // attachment status as reported by th ATTACHMENT_PORT_STATUS_AM_PB channel
  attachmentPortStatus?: PortStatusToConsumers.AsObject;
  // system status of the spotlight attachment
  spotlightSystemStatus?: SpotlightSystemStatus;
  // stats from the vehicle's device node, including availble transports, selected streams, etc.
  deviceNodePeerStats: DeviceNodePeerStats;
  // health summary of the vehicle's device node
  deviceNodeHealth: { [key: string]: DeviceNodeHealth };
  /**
   * In night autonomy navigation mode with nightsense modules activated
   */
  nightSenseActive: boolean;
  speakerMicStatus?: SpeakerMicStatus;
  speakerMicAudioFiles?: SpeakerMicAudioFiles;
}
